.watch {
  height: 70vh;
  background-color: red;
  background: black;

  .back {
    display: flex;
    align-items: center;
    position: absolute;
    top: 30px;
    left: 30px;
    color: white;
    cursor: pointer;
    z-index: 2;
  }
  .volver {
    color: white;
    font-size: 15px;
    margin-left: 58px;
    margin-top: 31px;
  }

  .backto {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 80px;
    left: 20px;
    color: white;
    cursor: pointer;
    z-index: 2;
  }

  .player {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

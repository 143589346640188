.div-1 {
    background-color: white;
}
.div-2 {
    background-color: white;
    padding-right: 20px;
    padding-left: 20px;
}
.navbarp {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: 10px;
    background-color: rgb(37, 36, 36);
    height: 40px;

}

.titulo{
    padding-left: 10px;
    color: white;
}

.alert {
    padding: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
    border-radius: 0.25rem;
    border: 1px solid;
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf;
  }


  .alert-primary {
    padding: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
    border-radius: 0.25rem;
    border: 1px solid;
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
  
  }  
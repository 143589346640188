:root {
  --main-color: #070e17;
}

.link {
  color: inherit;
  text-decoration: none;
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

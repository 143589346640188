.listaItema {
  width: 220px;
  height: 120px;
  background-color: var(--main-color);
  margin-right: 20px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgb(119, 116, 116);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .prueba {
    width: 100%;
    height: 140px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    width: 220px;
    height: 120px;
    position: relative;
    top: -15px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    box-shadow: 1px 1px 23px 1px rgb(255, 254, 254);
    border-radius: 10px;

    img {
      height: 120px;
    }
  }
}
